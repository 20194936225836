body {
  font-family: "Titillium Web", sans-serif;
}
a:hover {
  text-decoration: none;
}
a {
  text-decoration: none;
}
/*******************index*********************/
.icon-bar .fas.fa-thumbs-up {
  color: #f7f7f7;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.far.fa-paper-plane {
  font-size: 22px;
  color: white;
  cursor: pointer;
}
.icon-heading:hover {
  fill: #6bb43f;
}
.icon-heading:hover svg {
  fill: #6bb43f;
}
.icon-heading.active svg path {
  fill: #6bb43f;
}
.icon-heading.active a {
  color: #6bb43f;
}
.icon-heading:hover a {
  color: #6bb43f; 
}
 
.icon-heading a {
  color: #242c37;
  text-decoration: none;
  font-size: 15px;
}
.icon-heading {
  text-align: center;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 18px solid white;
  position: absolute;
  top: -13px;
  right: 6px;
  z-index: -1;
}
.dropbtn {
  background-color: transparent;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropdown-icon {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  text-align: left;
  position: absolute;
  right: -16px;
  top: 67px;
  z-index: 999;
  background-color: white;
  min-width: 174px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #d9d6d8;
  border-radius: 8px;
}
.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 15px;
}

.dropdown-icon:hover .dropdown-content {
  display: block;
}
.topic-1.active:after {
  content: "\f6ae";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 40%;
  right: 9px;
  color: white;
}
.icon-bar a {
  margin-right: 8px;
}

.topic-1.active {
  background: #7aba51;
}
.topic-1.active:hover {
  background: #7aba51;
}
.topic-1.active:hover .icon-bar {
  display: none;
}
.topics {
  height: 650px;
  overflow-y: auto;
  margin-right: 14px;
}
.topics::-webkit-scrollbar {
  width: 14px;
}
.topics::-webkit-scrollbar-track {
  background: #242c37;
  border-radius: 8px;
}
.topics::-webkit-scrollbar-thumb {
  background: #56bb5a;
  border-radius: 8px;
}
.project-title {
  background-color: #eae8e9;
  padding: 10px 0px;
}
.project-title p {
  font-size: 18px;
  color: #242c37;
  text-align: center;
  margin-bottom: 0px;
  font-weight: 600;
}
.project-title {
  border-bottom: 1px solid #d1cfd0;
}
.project-title span.icon1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -25px;
  font-size: 14px;
  color: #156ee8;
}
.project-title span.icon1 .fa {
  font-size: 22px;
  margin-left: 9px;
}
.topic-title p {
  font-size: 36px;
  margin-bottom: 0px;
  color: #48586d;
  text-align: center;
  font-weight: 600;
}
.border-bottom-title {
  border-bottom: 1px solid #48586d;
  margin-top: 8px;
}
a span.icon {
  font-size: 19px;
  color: #48586d;
}
.main-wrapper {
  /* display: flex; */
  /* flex-direction: column; */
}
.content {
  flex: 1;
}
.header {
  background-color: #4f6077;
  padding: 12px 0px;
}
.header-left {
  display: flex;
  flex-direction: row;
}
.header p {
  color: white;
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 600;
}
.header .icon .fa {
  color: #97a9b1;
  font-size: 20px;
  float: right;
  margin-top: -31px;
}
.button a button {
  background-color: #f14344;
  border: 1px solid #f14344;
  font-size: 15px;
  padding: 14px 10px;
  color: white;
  border-radius: 8px;
  font-weight: 300;
}
.button button:focus {
  outline: none;
}
.header-left img {
  margin-right: 15px;
}
.instructions a {
  color: #6bb43f;
  font-size: 15px;
  text-decoration: underline;
}

.instructions {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
}
.input-1 .form-group .form-control {
  font-size: 15px;
  color: #242c37;
  font-weight: 600;
}
.button {
  display: flex;
  justify-content: center;
}

.main-wrapper {
  /* box-shadow: 1px 0px 13px -5px #000000; */
}
.content-main {
  display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(3,86px);
    grid-gap: 3px;
    justify-content: center;
    border: none;
    margin-top: 0;
    padding: 4vw 6vw;
    padding-top: 7vw;
    font-size: 13px;
}
.content-main {
  margin-top: 10px;
}
.content-1 {
  background-color: #eae8e9;
    padding: 10px;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
}

span.text-icon {
  font-size: inherit;
}

span.capital p {
  font-size: 48px;
}
.text .input-group-text {
  background-color: #6bb43f;
  border: 1px solid #6bb43f;
  z-index: 1;
}
.text .input-group-text .fa {
  color: white;
}
.text {
  margin-top: 10px;
  padding: 0 12px;
}
.text .form-control {
  padding: 0.375rem 0.75rem !important;
  border: 1px solid #242c37 !important;
  border-radius: 0.25rem !important;
  height: 55px !important;
}
.select-type {
  display: flex;
  justify-content: space-between;
  background: #eae8e9;
  margin: 17px 11px 9px 11px;
  padding: 3px 11px 0px;
  position: relative; 
}
.font-size-changer-buttons {
  margin-top: 5px;
}
.select-right ul {
  display: flex;
  flex-direction: row;
}
.select-right ul li {
  margin-left: 10px;
  font-size: 18px;
  margin-top: 11px;
  color: #242c37;
  font-weight: 600;
}
.select-right ul li.active {
  color: #6bb43f;
}
.select-right ul a:hover {
  text-decoration: none;
}
.select-left p {
  font-size: 20px;
  color: #242c37;
  font-family: "Baskervville", serif;
  margin-bottom: 0px;
  margin-top: 16px;
}
.select-left .capital-a p {
  font-size: 40px;
  color: #242c37;
  font-family: "Baskervville", serif;
  margin-left: 10px;
  margin-top: -5px;
}
.select-left {
  display: flex;
  flex-direction: row;
}
.select-right ul li.plus-icon {
  background: #6bb43f;
  width: 23px;
  height: 23px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
}

/********************Project********************/
.heading-project p {
  font-size: 30px;
  color: #242c37;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
}

.icon-heading .dropdown-icon button.dropbtn:focus {
  outline: none;
}
.icon-bar .fas.fa-thumbs-up {
  color: #f7f7f7;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
 
.far.fa-paper-plane {
  font-size: 22px;
  color: white;
  cursor: pointer;
}
.icon-heading:hover {
  fill: #6bb43f;
}
.icon-heading:hover svg {
  fill: #6bb43f;
}
.icon-heading.active svg path {
  fill: #6bb43f;
}
.icon-heading a{
  font-size: 13px;
    line-height: 16px;
    display: block;
} 
.icon-heading svg{
  height:50px;
  width:100%; 
}
.icon-heading.active a {
  color: #6bb43f;
}
.icon-heading:hover a {
  color: #6bb43f;
  font-size: 15px;
}
.content-1 svg {
  height: 30px;
  width: 100%;
  /* border: 1px solid red; */
  margin-bottom: 3px;
}
.icon-heading .fas {
  font-size: 25px;
    margin-top: 5px;
    /* height: 50px; */ 
    margin-bottom: 10px;
}
.icon-heading a {
  color: #242c37; 
}
.icon-heading {
  text-align: center;
}
.input-1 .form-group {
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.input-3 .form-group {
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.input-1 .form-control {
  width: 52%;
}
.input-3 .form-control {
  margin-bottom: 0px;
  width: 83%;
}
.input-1 .form-group label {
  font-size: 15px;
  color: #242c37;
  font-weight: 600;
  margin-top: 10px;
}
.input-2 p {
  font-size: 15px;
  color: #242c37;
  font-weight: 600;
}
.input-3 .form-group label {
  font-size: 15px;
  color: #242c37;
  font-weight: 600;
  margin-top: 10px;
}
.input-3 .form-group .form-control:focus {
  box-shadow: none;
}
.input-1 .form-group .form-control:focus {
  box-shadow: none;
}
.input-button-1 button:focus {
  outline: none;
}
.input-button-1 button {
  background: #6bb43f;
  border: 1px solid #6bb43f;
  color: white;
  padding: 15px 27px;
  border-radius: 8px;
  float: right;
  margin-top: 10px;
  font-size: 18px;
}
.top-bar {
  background: #242c37;
  padding: 30px 0px;
}
.main-content {
  flex: 1;
}
.right-icon .fa {
  color: white;
  font-size: 20px;
  float: right;
  margin-top: -7px;
}
.existing-input {
  margin-top: 98px;
  margin-bottom: 20px;
  position: relative;
}
.existing-input-inner::-webkit-scrollbar {
  width: 14px;
}
.existing-input-inner::-webkit-scrollbar-track {
  background: #242c37;
  border-radius: 8px;
}
.existing-input-inner::-webkit-scrollbar-thumb {
  background: #56bb5a;
  border-radius: 8px;
}
.existing-input p {
  font-size: 15px;
  color: #242c37;
  font-weight: 600;
}
.existing-input a {
  font-size: 12px;
  color: #242c37;
  font-weight: 600;
  position: absolute;
  left: 47%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.existing-input a:hover {
  text-decoration: none;
}
.input-1 .form-group .form-control {
  border: 1px solid #242c37;
  border-radius: 8px;
}
.existing-input-inner {
  padding: 10px 10px;
  border: 1px solid #242c37;
  border-radius: 8px;
  height: 285px;
  overflow-x: auto;
}
.input-3 .form-group .form-control {
  border: 1px solid #242c37;
  border-radius: 8px;
}
.topic-inner {
  display: grid;
  grid-template-columns: repeat(4, 24%);
  grid-gap: 10px;
  margin-top: 27px;
}
/*.topic-inner{
  columns:4 200px;
  column-gap:18px;
  
}*/
.topic-1 {
  background: #ebe9e9;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d1cfd0;
  position: relative;
  display: flex;
  justify-content: center;
}
.topic-2 {
  background: #ebe9e9;
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid #d1cfd0;
  position: relative;
  display: flex;
  justify-content: center;
}
.input-group-text {
  height: 55px !important;
  border-radius: 0 0.6rem 0.6rem 0;
}
.input-2 {
  margin-top: 16px;
}
.input-3 {
  margin-top: 16px;
}
.input-group .form-control {
  border-radius: 0.6rem 0rem 0rem 0.6rem !important;
}
.input-group .form-control:focus {
  box-shadow: none;
  outline: none;
}
.topic-1 p {
  color: #242c37;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.topic-2 p {
  color: #242c37;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
}
button:hover {
  cursor: pointer;
}

::placeholder {
  color: #242c37 !important;
  font-weight: 600;
}
.topic-1 {
  position: relative;
  /*margin-bottom: 18px;
  display:inline-block;*/
}
.topic-2 {
  position: relative;
}
.topic-1:hover {
  background: #cae3b9;
  box-shadow: 1px 0px 8px -5px #000000;
  transition: 0.5s;
  cursor: pointer;
}
.topic-2:hover {
  background: #cae3b9;
  box-shadow: 1px 0px 8px -5px #000000;
  transition: 0.5s;
  cursor: pointer;
}
.topic-2.active:hover {
  background: #eae8e9;
  transition: 0.5s;
  cursor: pointer;
}

.topic-1:hover .icon-bar {
  display: block;
  background: #cae3b9;
  transition: 0.5s;
}
.topic-2:hover .icon-bar {
  display: block;
  background: #cae3b9;
  transition: 0.5s;
}
.icon-bar [data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
  font-family: "Titillium Web", sans-serif;
  color: white;
}
.icon-bar [data-title]:after {
  content: attr(data-title);
  background-color: #6bb43f;
  font-size: 9px;
  position: absolute;
  padding: 5px 6px 20px 6px;
  bottom: -1.6em;
  left: 50%;
  top: 20px;
  white-space: nowrap;
  text-align: center;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 5px;
  font-family: "Titillium Web", sans-serif;
}
.icon-bar [data-title] {
  position: relative;
}
.icon-bar {
  position: absolute;
  background: #ebe9e9;
  top: -29px;
  padding: 2px 22px;
  z-index: 99;
  border-radius: 10px 10px 0px 0px;
  border-left: 1px solid #d1cfd0;
  border-right: 1px solid #d1cfd0;
  border-top: 1px solid #d1cfd0;
  display: none;
}
.icon-bar .fa-thumbs-o-up {
  color: white;
}
.icon-bar .fa-share {
  color: #242c37;
}
.icon-bar .fa-times {
  color: #f14344;
}
.icon-bar .fa {
  margin-right: 8px;
}

/**************************Export************************/
.content-export {
  flex: 1;
}
.heading-project1 p {
  font-size: 20px;
  text-align: center;
  color: #242c37;
  font-weight: 600;
}
.heading-project1-para p {
  font-size: 15px;
  text-align: center;
  color: #242c37;
  font-weight: 600;
}
.heading-project1 {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 8% 92%;
}
.button-1 button {
  font-size: 18px;
  color: white;
  padding: 16px 10px;
  background-color: #6bb43f;
  border-radius: 8px;
  border: 1px solid #6bb43f;
}
.button-2 button {
  font-size: 18px;
  color: white;
  padding: 16px 19px;
  text-align: center;
  background-color: #6bb43f;
  border-radius: 8px;
  border: 1px solid #6bb43f;
}
.button-2 {
  text-align: center;
  margin-top: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button-1 {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button-1 a .fa {
  font-size: 23px;
  color: #000000;
  margin-left: 3px;
  margin-right: 3px;
}
.button-2 a .fa {
  font-size: 23px;
  color: #000000;
  margin-left: 3px;
}
.heading-project1 a .fa {
  font-size: 32px;
  color: #6bb43f;
}

/*****Existing-project*******/
.project-list-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e8e8e8;
  padding: 0px 10px;
  margin-top: 5px;
}
.project-list-inner p {
  color: #242c37;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}
.project-list-inner .fa {
  color: white;
  background: #35611a;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.open-center p {
  font-size: 30px;
  color: #35611a;
}
.project-list-inner:hover {
  background: #a4d18a;
}

/*******Popup********/
.popup-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 9999;
}
.popup-visible-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  background: white;
  padding: 50px 40px;
  min-width: 617px;
  min-height: 309px;
  display: grid;
  justify-content: center;
  align-content: center;
}
.popup-visible-screen p {
  font-size: 26px;
  color: #242c37;
  text-align: center;
}
.popup-visible-screen p.project-sent {
  font-size: 26px;
  color: #242c37;
  text-align: center;
}
.button-popup button {
  background: #6db33f;
  border: 1px solid #6db33f;
  color: white;
  font-size: 15px;
  border-radius: 8px;
  padding: 19px 25px;
  min-width: 94px;
}
.button-popup button:focus {
  outline: none;
}
.button-popup {
  display: flex;
  justify-content: center;
  align-items: center;
}
button.cancel-btn {
  background: #e01f1f;
  border: 1px solid #e01f1f;
  margin-right: 10px;
}
.popup-input .form-group .form-control {
  border: 1px solid #000000;
  border-radius: 8px;
}
.popup-input .form-group .form-control:focus {
  box-shadow: none;
  outline: none;
}
.topic-2.active:after {
  content: "1";
  position: absolute;
  top: 40%;
  right: 0px;
  color: white;
  border-radius: 50%;
  background: #242c37;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  box-shadow: 2px 3px 6px 0px rgb(0 0 0 / 61%);
}

/******Vote*****/
.dropdown-content.vote-mode {
  display: none;
  text-align: left;
  position: absolute;
  right: -75px;
  top: -42px;
  z-index: 999;
  background-color: #6bb43f;
  min-width: 190px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #6bb43f;
  border-radius: 8px;
}
.dropdown-content.vote-mode a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 15px;
}
.dropbtn li a {
  color: #242c37;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 18px solid #6bb43f;
  position: absolute;
  top: 40px;
  right: 67px;
  z-index: -1;
}
.mobile-wrapper {
  display: none;
}
.mobile-export-main {
  display: none;
}
.mobile-project {
  display: none;
}
.existing-mobile {
  display: none;
}
.topic-title .btn.btn-secondary {
  background: transparent;
  border: none;
}

.mobile-new {
  display: none;
}
.button-popup-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button-popup-mobile a {
  font-size: 40px;
}
.button-popup-mobile {
  border-top: 1px solid #bfbfc0;
  width: 100%;
}
.cancel-pop {
  padding: 0px 53px;
  border-right: 1px solid #bfbfc0;
}
.ok-pop {
  padding: 0px 53px;
}
.popup-visible-screen-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  background: white;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 22px;
}
.popup-visible-screen-mobile p {
  font-size: 26px;
  color: #242c37;
  text-align: center;
  font-weight: 800;
  margin: 20px 33px;
}
.renter-name {
  border-top: 1px solid #bfbfc0;
}
.renter-name p {
  text-align: center;
  color: #1e8afd;
}
.mobile-export {
  display: none;
}
/****landcape****/
/*.left-icon a .fa {
  font-size: 32px;
  color: #6bb43f;
}*/
.topic-title-landcsape p {
  font-size: 60px;
  margin-bottom: 0px;
  color: #48586d;
  text-align: center;
  font-weight: 600;
}
a span.icon-landscape {
  font-size: 41px;
  color: #48586d;
}
.topic-title-landcsape {
  display: flex;
  flex-direction: row;
}
.heading-project1-landscape p {
  font-size: 60px;
  text-align: center;
  color: #242c37;
  font-weight: 600;
}
.heading-project1-para-landscape p {
  font-size: 30px;
  text-align: center;
  color: #242c37;
  font-weight: 600;
}
.heading-project1-para-landscape {
  margin-top: 80px;
}
.landscape-export {
  display: none;
}
.button-1-landscape button {
  font-size: 23px;
  color: white;
  padding: 30px 70px;
  background-color: #6bb43f;
  border-radius: 8px;
  border: 1px solid #6bb43f;
}
.button-1-landscape {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 78px;
}
.button-1-landscape a .fa {
  font-size: 23px;
  color: #000000;
  margin-left: 4px;
  margin-right: 15px;
}
.button-2-landscape button {
  font-size: 23px;
  color: white;
  padding: 30px 144px;
  text-align: center;
  background-color: #6bb43f;
  border-radius: 8px;
  border: 1px solid #6bb43f;
}
.button-2-landscape {
  text-align: center;
  margin-top: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button-2-landscape a .fa {
  font-size: 23px;
  color: #000000;
  margin-left: 3px;
}
.instructions-landscape a {
  color: #6bb43f;
  font-size: 34px;
  text-decoration: underline;
}

.instructions-landscape {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
/* 
@media only screen and (max-width: 1200px) {
  .content-main {
    display: grid;
    grid-template-columns: repeat(3, 65px);
    grid-template-rows: repeat(3, 65px);
    grid-gap: 3px;
    justify-content: center;
    margin-top: 10px;
  }
  .content-1 {
    background-color: #eae8e9;
    padding: 0;
    min-height: 65px;
    min-width: 65px;
  }
  span.text-icon {
    font-size: 11px;
  }

  .dropdown-content {
    min-width: 129px;
  }
  .dropdown-content a {
    padding: 8px 16px;
    font-size: 10px !important;
  }
  .font-size-down {
    width: 30px;
    height: 30px;
  }
  .font-size-up {
    width: 21px;
    height: 21px;
    margin-right: 2px;
  }
  .font-size-changer-buttons {
    width: 68px;
    height: 31px;
    margin-top: 4px;
  }
  .dropdown-content.vote-mode {
    right: -55px;
    top: -42px;
    min-width: 130px;
  }
  .arrow-down {
    top: 40px;
    right: 46px;
  }
  .form-control {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1025px) {
  .content-1 {
    min-height: 60px;
    min-width: 60px;
  }
  .content-main {
    grid-template-columns: repeat(3, 60px);
    grid-template-rows: repeat(3, 60px);
  }
  .icon-heading .fas {
    font-size: 18px;
    margin-top: 5px;
  }
  span.text-icon {
    font-size: 10px;
  }
  .select-right ul li {
    margin-left: 6px;
  }
}

@media only screen and (max-width: 806px) {
  .content-1 {
    min-height: 50px;
    min-width: 50px;
  }
  .content-main {
    grid-template-columns: repeat(3, 50px);
    grid-template-rows: repeat(3, 50px);
  }
  span.text-icon {
    font-size: 8px;
  }
  .dropdown-content.vote-mode a {
    padding: 8px 7px;
  }
  .dropdown-content.vote-mode {
    right: -46px;
    top: -27px;
    min-width: 115px;
  }
  .arrow-down {
    top: 26px;
    right: 38px;
  }
  .input-group-text {
    height: 49px;
  }
  .text .form-control {
    height: 49px;
  }
  .select-type {
    padding: 2px 3px 0;
  }
}
@media only screen and (max-width: 690px) {
  .content-1 {
    min-height: 50px;
    min-width: 50px;
  }
  .content-main {
    grid-template-columns: repeat(3, 40px);
    grid-template-rows: repeat(3, 40px);
  }
  span.text-icon {
    font-size: 6px;
  }
} */



@media only screen and (max-width: 1350px) {
  body{
      zoom: 4;
  }
  iframe body{
      zoom: 1;
  }
}
@media only screen and (max-width: 1000px) {
  body{
      zoom: 3;
  }
  iframe body{
      zoom: 1;
  }
}
@media only screen and (max-width: 700px) {
  body{
      zoom: 2;
  }
  iframe body{
      zoom: 1;
  }
}
@media only screen and (max-width: 500px) {
  body{
      zoom: 1.5;
  }
  iframe body{
      zoom: 1;
  }
  .block a span{
      border: 2px solid #2e3235;
  }
}
@media only screen and (max-width: 450px) {
  body{
      zoom: 1.33;
  }
  iframe body{
      zoom: 1;
  }
  .block a span{
      border: 3px solid #2e3235;
  }
}
@media only screen and (max-width: 435px) {
  body{
      zoom: 1.25;
  }
  iframe body{
      zoom: 1;
  }
  .block a span{
      border: 2px solid #2e3235;
  }
}
@media only screen and (max-width: 400px) {
  body{
      zoom: 1.11;
  }
  iframe body{
      zoom: 1;
  }
  .block a span{
      border: 3px solid #2e3235;
  }
}
@media only screen and (max-width: 350px) {
  body{
    zoom: 1;
  }
  iframe body{
      zoom: 1;
  }
  .block a span{
      border: 2px solid #2e3235;
  }
}
@media only screen and (max-width: 300px) {
  body{
    zoom: .88;
  }
  iframe body{
      zoom: 1;
  }
  /* .listing-body{
      height: calc(100% - 162px);
  } */
}
@media only screen and (max-width: 265px) {
  body{
    zoom: .8;
  }
  iframe body{
      zoom: 1;
  }
}
@media only screen and (max-width: 250px) {
  body{
    zoom: .67;
  }
  iframe body{
      zoom: 1;
  }
}
@media only screen and (max-width: 200px) {
  body{
    zoom: .57;
  }
  iframe body{
      zoom: 1;
  }
  .block a span{
      border: 3px solid #2e3235;
  }
}
@media only screen and (max-width: 165px) {
  body{
    zoom: .5;
  }
  iframe body{
      zoom: 1;
  }
  .block a span{
      border: 2px solid #2e3235;
  }
}
@media only screen and (max-width: 135px) {
  body{
    zoom: .4;
  }
  iframe body{
      zoom: 1;
  }
}
@media only screen and (max-width: 115px) {
  body{
    zoom: .335;
  }
  iframe body{
      zoom: 1;
  }
}
@media only screen and (max-width: 100px) {
  body{
    zoom: .25;
  }
  iframe body{
      zoom: 1;
  }
}
@media only screen and (max-width: 70px) {
  body{
    zoom: .2;
  }
  iframe body{
      zoom: 1;
  }
}


.main{
  height: calc(100vh - 90px);
  /* border: 2px solid; */
  display: flex;
  flex-direction: column;
} 
.main-wrapper {
  height: 100%;
}
.main-wrapper .content{
  height: 100%;
    display: flex;
    flex-direction: column;
}
.main-wrapper .content .footer{
  margin-top:auto;
}